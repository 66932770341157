<template>
	<ObiText class="obi-step-item">
		<ObiStepItem></ObiStepItem>
	</ObiText>
</template>

<script>
export default {
	name: "ObiStepItem",
	props: {
		value: {},
		id: {
			type: String,
			default: null,
		},
	},
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/variables.scss";

.obi-input {
	.form-label {
		color: $secondary;
	}
	.form-control {
		color: $secondary;
	}

	.form-control[readonly] {
		background-color: transparent;
	}

	&.is-invalid {
		.input-group-text {
			border-color: $form-feedback-invalid-color;
		}
	}

	&.is-valid {
		.input-group-text {
			border-color: $form-feedback-valid-color;
		}
	}

	.invalid-feedback {
		text-align: left;
	}

	.for-toolbar {
		.form-control,
		.input-group-text.input-group-icon {
			padding: 6.75px 10px;
		}
	}
}
</style>
